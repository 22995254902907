import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

/**
 * お問い合わせでのナビゲーション
 */
export const InquiryNavi = () => {
  const { t } = useTranslation()

  return (
    <nav className="side_nav">
      <div className="sticky">
        <h2>{t("お問い合わせ", "お問い合わせ")}</h2>
        <ul>
          <li>
            <Link to="/inquiry">
              {t("資料請求・お問い合わせ", "資料請求・お問い合わせ")}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
